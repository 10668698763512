<template>
	<div class="cont">
		<div class="imgList">
			<!-- <a :href="kefu_link">
				<img src="../assets/kefu.png" alt="">
			</a> -->
			<a href="/index/user/lang.html">
				<img src="../assets/lang.png" alt="">
			</a>
		</div>
		<div class="logo">
			<img src="../assets/logo.png"/>
		</div>


		<div class="content">
			<div class="box box1">
				<ul>
					<li>
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAkCAYAAAAKNyObAAADNklEQVRYhcWYS4jNURzHP3NdjzJkkphClPFmFhZivEeSjWLhsRiklCxkKBZkY2GlLJkN0zRjIQt5P0pilEIIjYU8I/KeKebhq6Nz69/pnP89996Z8a2zOL9zzvd87/n9zu//O7dMEpGoAuqAJcB0IAO8B+4Cp4BLsUTRMOIi2kFJ3UrHWUljIvmiWsyk5jyikngnaWJ/iTtSgLAc3kga3Nfi5hYhLIdjvSEu7ULcAmpKCOcJwKtEfwBQD7wFWmIIMgH75BKFGWx1+n+AmUAz8BBYnpchcKQ7S3BpDnc8vGOdORvS3Bo6uZklnprBJGCIYzMufZbom1OcGiIIiRvZC+JG2Oaizek3FCruTy+IU4Dnu9NfEPJUSNz70rXxCfgSuedqH0FI3P3SdP3DE6DbY6/02Kb5CELiLpam6x/OBfar9tjLfQQhcR+A0yUI6wEaPfa5wCiP/Wch4gz2FK+NvcA3j317YP5TrzXP921rEcn3WoBrfMqaGcVUJabtK0DYdUmZAE9rYM3NYkumXFsjqS1FVIekQynrj6asnVJMVeLCxOcaYBkwC8gCb4BW4AzwOmXtYqAW2AyMs7avtuR/FFyV58RMVbtJ0rDIE14laXnK+BBJuyS121PbIWlRoW6tltQgqVNSj6RBznitpDrPuhN20xuS1ub50fcSrjUVzPoYcYedmHjsib8cmpyx/c7aq5KqUkRed+abR1KFT9wwe3NcPHEIGxPjvyVlE2MHPeuNC1cGxJmb/cqZ/0LSaCXquawtyxd6wrInpf/LSeTuXIOh9nO41DNmqpaNjm0icIUEcRMwO3Bn3IIxmfnlfNzTvjjngQqP/ba98UkYLTvM0c5PyUEGXZIqE66YIOmBpI+S1jlu8oVFEqFXWb1n7iMz0JKH0OByRBrZEsFjbv/wQJJ38cPE2rwUV+SwwsbkceA50A50WjeNsMl5WwTPQPuqc0syX8WcyRbwXqjpheeiwRiPrcxjU8YG9f9Gl2f/8kxAdV/Ct1+tx3Yha3Ncf8KXbm4AL60XjfgO4KSpSp6GHhh9hDmxDyjzK3b3ozCT7KNfdrl6zqQCI9L8ndoX+Gz/WToQTQ78Bbh5nCKLkrzHAAAAAElFTkSuQmCC"
							alt="" class="ico">
							<input type="text" name="tel" :placeholder="this.please_enter" @input="inname">
					</li>
					<li>
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAACy0lEQVRYhe2YT4hNURzHP+95TxnTkH/1kj9FFFY0yAJjY2xQdspqFqxkgbKVja3yFNmN0lAsLCwpDUU2/iz8GX9GyJDweAzeT7/p3Drvdu5zzrnPaxa+dXv3nXvP73zv75zf34KI4IkSsAPYAiwCpptpaQEF8/sNeAVcAy4Dda9llJDHNSAiIxKPURHZ77OWD5lqDiJpnMtL6GgbySSotlqz1RlaATxwjD8BrgCvgXLG3F9AN7AR2OR4vgG4GXqGBh1fd0FEyp7nLrn2OeRcDd2ykoiMpYSMBhJp9XE/RKTH9W4xQ+ULgDmpsYtZe+uBM6lXpgJLXNOyCE1zjL3PQajmGOsKIdRwjGUdYB+UPNfIJORCIeDdNFxzneYdQqgj+E/ob8giNO4Y+51jnZ+eY5mEvhgrEIuIX/rgRmL2Dcu6XK6gKZYtBgaAdUAP0GsJUbMdAZ5mmHAraFybBayxyKgi7gAfgGHgNPB24olx2TtFpN7eoB6EjyLSl0T7rKjeaehWVVR1xyYBGYzzPKiE1k4CMgl6i1kxJRCazD9qg5xC0VQHsVDT3W1SieXAZuB+DnnjasKfcwjYBtyw/l83buMZMC9CXk019C6SzHCKTALV+IlImWNK6Hnk5MctnsVu2wsldC9y8voWz/ojZT5UL70qh4c94kjU+3PIqyRCXuYQ0meR6RKR75Fy7tpVx1CkihUV676cI8c6jzW5moPQG+v+U1Za4YGzNiFNLS5FCppv0pWZpp6L0dBJk4o05UMVU6+HomaSNyUyBZgRWKHUTVE6ETHsr1HV740gpE2FucBso6XQcmlXU/hymO2pHBYXikO+zYZOkDoc2rA6ICKNf0BEuyjbYztoK0VkqE1ENG8+LiLdsR00G1qB7AG2AssCDqzWd7eMSxn06aCEtIUTaDmzGlgKLDRWpu0brd++Gn+i2aO2/m6bbNIPwB+YVoYJP+o7+gAAAABJRU5ErkJggg=="
							alt="" class="ico">
						<input type="password" name="pwd" :placeholder="this.your_password" @input="inpass">
					</li>
				</ul>
				<div class="loginBtn" @click="login">
					<div class="btn-inner">{{login_1}}</div>
				</div>
			</div>
		</div>

		<div class="tips">
			{{no_account}}
			<i>
				<a style="color: #f24255;" @click="register">{{reg_title}}</a>
			</i>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Login",
		props: {
			msg: String,
		},
		data() {
			return {
				user: {
					tel: "",
					pwd: "",
				},
				reme: false,

				please_enter: "",
				your_password: "",
				login_1: "",
				no_account: "",
				reg_title: "",
				kefu_link: "",
			};
		},
		created() {
			// 初始化操作，如获取数据
			this.fetchData();
		},
		methods: {
			async fetchData() {
				var result = await this.$proxy.getvuelang();
				// console.log(result);
				var info = result.info;
				this.please_enter = info.please_enter;
				this.your_password = info.your_password;
				this.login_1 = info.login_1;
				this.no_account = info.no_account;
				this.reg_title = info.reg_title;
				this.kefu_link = info.kefu_link;

				if (typeof this.please_enter == "undefined") {
					this.please_enter = 'Please enter the platform account';
					this.your_password = 'your password';
					this.login_1 = 'login';
					this.no_account = 'No account?';
					this.reg_title = 'Register';
				}
			},
			// 获取用户名和密码
			inname(e) {
				this.user.tel = e.target.value;
			},
			inpass(e) {
				this.user.pwd = e.target.value;
			},
			// 跳转到注册
			register() {
				this.$router.push({
					path: "/register"
				});
			},
			// 跳转到语言
			lang() {
				window.open('/index/user/lang', '_self');
			},
			// 登录
			async login() {
				if (this.user.tel === "" || this.user.pwd === "") {
					this.$vs.notification({
						position: "top-center",
						icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
						color: "warn",
						title: '<span style="font-size: 18px;">Error</span>',
						text: '<span style="font-size: 14px;">Please enter account / password</span>',
					});
					return false;
				}
				// 登录接口
				var result = await this.$proxy.login(this.user);
				// console.log(result)
				if (result.code === 0) {
					//存入token
					// localStorage.setItem('token',result.data['token']);
					// alert(localStorage.token);
					const loading = this.$vs.loading({
						type: "circles",
						color: "#d5397b",
						text: "Login success",
					});
					this.hasOpenLoading = true;
					setTimeout(() => {
						loading.close();
						this.hasOpenLoading = false;
					}, 2000);
					sessionStorage.setItem('login_flag', '1');
					window.open('/index/index/home', '_self');
					// this.$router.replace("/index/index/home");
				} else {
					this.$vs.notification({
						position: "top-center",
						icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
						color: "warn",
						title: '<span style="font-size: 18px;">Error</span>',
						text: '<span style="font-size: 14px;">'+result.info+'</span>',
					});
				}
			},
		},
	};
</script>

<style scoped>
	body {
		font-size: 1rem;
		color: #333;
	}
	.cont {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background: url(../assets/background.png) no-repeat;
		background-size: 100% 100%;
	}
	
	.imgList {
		position: relative;
		height: 50px;
		align-items: center;
		display: flex;
		justify-content: flex-end;
	}

	.imgList a {
		margin-right: 16px;
	}

	.imgList img {
		width: 30px;
		height: 30px;
	}
	
	.logo {
		display: flex;
		flex-wrap: nowrap;
		height: 180px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	.logo img {
		height: 117px;
		border-radius: 8px;
	}

	.content {
		padding: 0 28px;
	}

	.box {
		margin-top: 2vh;
	}
	ul, li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.box ul li {
		background-color: hsla(0, 0%, 98%, .5);
		border-color: #ffffff;
		height: 7vh;
		border-radius: 25px;
		display: flex;
		align-items: center;
		padding: 11px;
	}
	.box1 ul li + li, .box2 ul li + li {
		margin-top: 3vh;
	}
	.box ul li .ico {
		width: 22px;
	}
	img {
		vertical-align: middle;
	}
	.box ul li input {
		font-size: 16px;
		background: transparent;
		flex: 1;
		color: #fff;
		text-align: center;
	}
	button, input, select {
		outline-style: none;
		border: 0;
	}

	
	.loginBtn {
		height: 100px;
		border: 0px solid black;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	.btn-inner {
		height: 50px;
		line-height: 50px;
		background-color: rgb(225, 225, 225);
		border-radius: 25px;
		transition-duration: 0s;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
	}
	.tips {
		display: flex;
		width: 100%;
		text-align: center;
		font-size: 14px;
		color: #fff;
		flex-wrap: nowrap;
		justify-content: center;
		height: 40px;
		align-items: center;
	}
</style>
