<template>
	<div class="cont">
		<div class="imgList">
			<a @click="goback">
				<img src="../assets/leftIcon.png" alt="">
			</a>
			<!-- <a href="/index/user/lang.html">
				<img src="../assets/lang.png" alt="">
			</a> -->
		</div>
		<div class="logo">
			<div class="logo-text">{{registered_account}}</div>
		</div>
		<div class="content">
			<div class="box box1">
				<ul>
					<li>
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAkCAYAAAAKNyObAAADNklEQVRYhcWYS4jNURzHP3NdjzJkkphClPFmFhZivEeSjWLhsRiklCxkKBZkY2GlLJkN0zRjIQt5P0pilEIIjYU8I/KeKebhq6Nz69/pnP89996Z8a2zOL9zzvd87/n9zu//O7dMEpGoAuqAJcB0IAO8B+4Cp4BLsUTRMOIi2kFJ3UrHWUljIvmiWsyk5jyikngnaWJ/iTtSgLAc3kga3Nfi5hYhLIdjvSEu7ULcAmpKCOcJwKtEfwBQD7wFWmIIMgH75BKFGWx1+n+AmUAz8BBYnpchcKQ7S3BpDnc8vGOdORvS3Bo6uZklnprBJGCIYzMufZbom1OcGiIIiRvZC+JG2Oaizek3FCruTy+IU4Dnu9NfEPJUSNz70rXxCfgSuedqH0FI3P3SdP3DE6DbY6/02Kb5CELiLpam6x/OBfar9tjLfQQhcR+A0yUI6wEaPfa5wCiP/Wch4gz2FK+NvcA3j317YP5TrzXP921rEcn3WoBrfMqaGcVUJabtK0DYdUmZAE9rYM3NYkumXFsjqS1FVIekQynrj6asnVJMVeLCxOcaYBkwC8gCb4BW4AzwOmXtYqAW2AyMs7avtuR/FFyV58RMVbtJ0rDIE14laXnK+BBJuyS121PbIWlRoW6tltQgqVNSj6RBznitpDrPuhN20xuS1ub50fcSrjUVzPoYcYedmHjsib8cmpyx/c7aq5KqUkRed+abR1KFT9wwe3NcPHEIGxPjvyVlE2MHPeuNC1cGxJmb/cqZ/0LSaCXquawtyxd6wrInpf/LSeTuXIOh9nO41DNmqpaNjm0icIUEcRMwO3Bn3IIxmfnlfNzTvjjngQqP/ba98UkYLTvM0c5PyUEGXZIqE66YIOmBpI+S1jlu8oVFEqFXWb1n7iMz0JKH0OByRBrZEsFjbv/wQJJ38cPE2rwUV+SwwsbkceA50A50WjeNsMl5WwTPQPuqc0syX8WcyRbwXqjpheeiwRiPrcxjU8YG9f9Gl2f/8kxAdV/Ct1+tx3Yha3Ncf8KXbm4AL60XjfgO4KSpSp6GHhh9hDmxDyjzK3b3ozCT7KNfdrl6zqQCI9L8ndoX+Gz/WToQTQ78Bbh5nCKLkrzHAAAAAElFTkSuQmCC"
							alt="" class="ico">
						<input type="text" name="tel" v-model="user.tel" :placeholder="this.please_set" value="">
					</li>
					<li>
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAACy0lEQVRYhe2YT4hNURzHP+95TxnTkH/1kj9FFFY0yAJjY2xQdspqFqxkgbKVja3yFNmN0lAsLCwpDUU2/iz8GX9GyJDweAzeT7/p3Drvdu5zzrnPaxa+dXv3nXvP73zv75zf34KI4IkSsAPYAiwCpptpaQEF8/sNeAVcAy4Dda9llJDHNSAiIxKPURHZ77OWD5lqDiJpnMtL6GgbySSotlqz1RlaATxwjD8BrgCvgXLG3F9AN7AR2OR4vgG4GXqGBh1fd0FEyp7nLrn2OeRcDd2ykoiMpYSMBhJp9XE/RKTH9W4xQ+ULgDmpsYtZe+uBM6lXpgJLXNOyCE1zjL3PQajmGOsKIdRwjGUdYB+UPNfIJORCIeDdNFxzneYdQqgj+E/ob8giNO4Y+51jnZ+eY5mEvhgrEIuIX/rgRmL2Dcu6XK6gKZYtBgaAdUAP0GsJUbMdAZ5mmHAraFybBayxyKgi7gAfgGHgNPB24olx2TtFpN7eoB6EjyLSl0T7rKjeaehWVVR1xyYBGYzzPKiE1k4CMgl6i1kxJRCazD9qg5xC0VQHsVDT3W1SieXAZuB+DnnjasKfcwjYBtyw/l83buMZMC9CXk019C6SzHCKTALV+IlImWNK6Hnk5MctnsVu2wsldC9y8voWz/ojZT5UL70qh4c94kjU+3PIqyRCXuYQ0meR6RKR75Fy7tpVx1CkihUV676cI8c6jzW5moPQG+v+U1Za4YGzNiFNLS5FCppv0pWZpp6L0dBJk4o05UMVU6+HomaSNyUyBZgRWKHUTVE6ETHsr1HV740gpE2FucBso6XQcmlXU/hymO2pHBYXikO+zYZOkDoc2rA6ICKNf0BEuyjbYztoK0VkqE1ENG8+LiLdsR00G1qB7AG2AssCDqzWd7eMSxn06aCEtIUTaDmzGlgKLDRWpu0brd++Gn+i2aO2/m6bbNIPwB+YVoYJP+o7+gAAAABJRU5ErkJggg=="
							alt="" class="ico">
						<input type="password" name="pwd" v-model="user.pwd" :placeholder="this.your_password" value="">
					</li>
					<li>
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAACy0lEQVRYhe2YT4hNURzHP+95TxnTkH/1kj9FFFY0yAJjY2xQdspqFqxkgbKVja3yFNmN0lAsLCwpDUU2/iz8GX9GyJDweAzeT7/p3Drvdu5zzrnPaxa+dXv3nXvP73zv75zf34KI4IkSsAPYAiwCpptpaQEF8/sNeAVcAy4Dda9llJDHNSAiIxKPURHZ77OWD5lqDiJpnMtL6GgbySSotlqz1RlaATxwjD8BrgCvgXLG3F9AN7AR2OR4vgG4GXqGBh1fd0FEyp7nLrn2OeRcDd2ykoiMpYSMBhJp9XE/RKTH9W4xQ+ULgDmpsYtZe+uBM6lXpgJLXNOyCE1zjL3PQajmGOsKIdRwjGUdYB+UPNfIJORCIeDdNFxzneYdQqgj+E/ob8giNO4Y+51jnZ+eY5mEvhgrEIuIX/rgRmL2Dcu6XK6gKZYtBgaAdUAP0GsJUbMdAZ5mmHAraFybBayxyKgi7gAfgGHgNPB24olx2TtFpN7eoB6EjyLSl0T7rKjeaehWVVR1xyYBGYzzPKiE1k4CMgl6i1kxJRCazD9qg5xC0VQHsVDT3W1SieXAZuB+DnnjasKfcwjYBtyw/l83buMZMC9CXk019C6SzHCKTALV+IlImWNK6Hnk5MctnsVu2wsldC9y8voWz/ojZT5UL70qh4c94kjU+3PIqyRCXuYQ0meR6RKR75Fy7tpVx1CkihUV676cI8c6jzW5moPQG+v+U1Za4YGzNiFNLS5FCppv0pWZpp6L0dBJk4o05UMVU6+HomaSNyUyBZgRWKHUTVE6ETHsr1HV740gpE2FucBso6XQcmlXU/hymO2pHBYXikO+zYZOkDoc2rA6ICKNf0BEuyjbYztoK0VkqE1ENG8+LiLdsR00G1qB7AG2AssCDqzWd7eMSxn06aCEtIUTaDmzGlgKLDRWpu0brd++Gn+i2aO2/m6bbNIPwB+YVoYJP+o7+gAAAABJRU5ErkJggg=="
							alt="" class="ico">
						<input type="password" name="deposit_pwd" v-model="user.deposit_pwd" :placeholder="this.login_pass2" value="">
					</li>
					<li>
						<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAACy0lEQVRYhe2YT4hNURzHP+95TxnTkH/1kj9FFFY0yAJjY2xQdspqFqxkgbKVja3yFNmN0lAsLCwpDUU2/iz8GX9GyJDweAzeT7/p3Drvdu5zzrnPaxa+dXv3nXvP73zv75zf34KI4IkSsAPYAiwCpptpaQEF8/sNeAVcAy4Dda9llJDHNSAiIxKPURHZ77OWD5lqDiJpnMtL6GgbySSotlqz1RlaATxwjD8BrgCvgXLG3F9AN7AR2OR4vgG4GXqGBh1fd0FEyp7nLrn2OeRcDd2ykoiMpYSMBhJp9XE/RKTH9W4xQ+ULgDmpsYtZe+uBM6lXpgJLXNOyCE1zjL3PQajmGOsKIdRwjGUdYB+UPNfIJORCIeDdNFxzneYdQqgj+E/ob8giNO4Y+51jnZ+eY5mEvhgrEIuIX/rgRmL2Dcu6XK6gKZYtBgaAdUAP0GsJUbMdAZ5mmHAraFybBayxyKgi7gAfgGHgNPB24olx2TtFpN7eoB6EjyLSl0T7rKjeaehWVVR1xyYBGYzzPKiE1k4CMgl6i1kxJRCazD9qg5xC0VQHsVDT3W1SieXAZuB+DnnjasKfcwjYBtyw/l83buMZMC9CXk019C6SzHCKTALV+IlImWNK6Hnk5MctnsVu2wsldC9y8voWz/ojZT5UL70qh4c94kjU+3PIqyRCXuYQ0meR6RKR75Fy7tpVx1CkihUV676cI8c6jzW5moPQG+v+U1Za4YGzNiFNLS5FCppv0pWZpp6L0dBJk4o05UMVU6+HomaSNyUyBZgRWKHUTVE6ETHsr1HV740gpE2FucBso6XQcmlXU/hymO2pHBYXikO+zYZOkDoc2rA6ICKNf0BEuyjbYztoK0VkqE1ENG8+LiLdsR00G1qB7AG2AssCDqzWd7eMSxn06aCEtIUTaDmzGlgKLDRWpu0brd++Gn+i2aO2/m6bbNIPwB+YVoYJP+o7+gAAAABJRU5ErkJggg=="
							alt="" class="ico">
						<input type="password" name="self_pwd" v-model="user.self_pwd" :placeholder="this.with_q_zjmm" value="">
					</li>
					<li>
						<img data-v-b19b4918="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAYAAADFniADAAACrUlEQVRYhe2Yu2sVURDGfze5IYkPooVFghCx8VWoWBiDxoAIvl9gYeFfIPgAU1mnURBsrCztBDFejXoVHyTqFUEEsRBLxUIJFhaKeYwMTODksLk7u9ncpPCDc8/ds3Nmvp0zZ87OIiJJrVdE3sj8YkREtiXZL+lPhL1AFfgMPAR+ASVrc4VYWwEcAVYDO4BaqDcm1QL8AF4BBwogkYZnwFqge4Zc5LoeW7Atsyxr0W272dsQ6m2KnqTT+r8N8JLit/Vd4WBMasr65sZwomz9ZDgYk5Kon2+UkuzFpBKF5hGJTohJEWzbRnqqHA6WI6FvFldeUppCjgHjwAdgq+W1R875bdZPzBgNtmKfiLy2LbresfW7ROR9kPMngv+3RaTNoWM6JbwVkf44JVwGbhjjCWf2vgBsDq7DHXscOOXQ8ce8rDZvAtewmDoBDADfgUHL6FPp+liXcn+jQ4eGyRhwxo61s8BJdVc1cPuYiHwRkU6H60+nHNe9Dh3dIvJVRD4F86p6oxYp09hY4zxOrs5C6Lxzvj78ZDS3pjdGE5TqWIdT8ZVo7jnnvBYRGU6yPRspsZ3Y6jRw3Tx8McOBnURIMVKPlOKliLQ7jWzKQKhSx+ZIUkYP0Qs8BTocO+mjQ0bTxj3gUD2hNFKKHmAYWOaQrQc9PSrAwTRBDymFeuyxHSt5MQTs98z1ksI8psRac5C6m+X1OgspxW7gCbDcKa+evQ8czmIkKynFTgvWthQ51X0nTwGSh5Siz8qweq/NlbwVUV5Sil22lO0J9zLFUJGkFP1WsC61a42hB1ljqGhS2FIOWaK9Beybq8L4dTgv9lgrBEV4qnD8J+VFUwNLdC+am6yaWEwYV1LPFxmpF/rRbAkwatXtQuOd5r3pL3krgUvAUWDVAhDTmlOPpkHg5z+wwENC7AKnfgAAAABJRU5ErkJggg==" 
							alt="" class="ico">
						<input type="text" name="login_qr" v-model="user.invite_code" :placeholder="this.login_qr" value="">
					</li>
				</ul>
				<div class="loginBtn" @click="regit">
					<div class="btn-inner">{{registered_account}}</div>
				</div>
			</div>
		</div>

		<div class="tips">
			<i>
				<a style="color: #000;" @click="goLogin">{{login_1}}</a>
			</i>
		</div>
	</div>
</template>





<script>
export default {
    name: "Register",
    props: {
        msg: String,
    },
    data() {
        return {
            user: {
                user_name: "",
                tel: "",
                pwd: "",
                deposit_pwd: "",
                self_pwd: "",
                invite_code: "",
            },
            user_name: "",
            platform_account: "",
            r_password: "",
            qu_newpass: "",
            payment_password: "",
            extension_code: "",
            registered_account: "",
            login_1: "",
            enter_name: "",
            please_set: "",
            your_password: "",
            login_pass2: "",
            with_q_zjmm: "",
            login_qr: "",
        };
    },
    created() {
        // 初始化操作，如获取数据
        this.fetchData();
    },
    methods: {
        async fetchData() {
            var result = await this.$proxy.getvuelang();
            // result = JSON.parse(result);
            var info = result.info;
            this.user_name = info.user_name;
            this.platform_account = info.platform_account;
            this.r_password = info.r_password;
            this.qu_newpass = info.qu_newpass;
            this.payment_password = info.payment_password;
            this.extension_code = info.extension_code;
            this.registered_account = info.registered_account;
            this.login_1 = info.login_1;

            this.enter_name = info.enter_name;
            this.please_set = info.please_set;
            this.your_password = info.your_password;
            this.login_pass2 = info.login_pass2;
            this.with_q_zjmm = info.with_q_zjmm;
            this.login_qr = info.login_qr;

            if(typeof this.user_name == "undefined"){
                this.user_name = 'username';
                this.platform_account = 'Platform account';
                this.r_password = 'password';
                this.qu_newpass = 'Confirm password';
                this.payment_password = 'modify payment password';
                this.extension_code = 'invitation code';
                this.registered_account = 'registered account';
                this.login_1 = 'login';

                this.enter_name = 'Please enter a username';
                this.please_set = 'Please set your platform login account';
                this.your_password = 'your password';
                this.login_pass2 = 'Please confirm the password';
                this.with_q_zjmm = 'Please enter your payment password';
                this.login_qr = 'Please enter the invitation code';
            }
        },
      // 跳转到语言
        lang() {
            window.open('/index/user/lang.html','_self');
        },
        // 注册用户
        async regit() {
            if (this.user.tel == "" || this.user.pwd == "" || this.user.pwd !== this.user.deposit_pwd) {
                this.$vs.notification({
                    position: "top-center",
                    icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
                    color: "danger",
                    title: '<span style="font-size: 18px;">Error</span>',
                    text: '<span style="font-size: 14px;">The two passwords are inconsistent</span>',
                });
                return false;
            }

            if(this.user.invite_code.trim().length <= 0){
                this.$vs.notification({
                    position: "top-center",
                    icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
                    color: "danger",
                    title: '<span style="font-size: 18px;">Error</span>',
                    text: '<span style="font-size: 14px;">'+this.login_qr+'</span>',
                });
                return false;
            }

            // 注册接口
            var result = await this.$proxy.regdit(this.user);
            // console.log(result);
            if (result.code == 1) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons" style="font-size: 24px;">error</span>',
                    color: "warn",
                    title: '<span style="font-size: 18px;">Error</span>',
                    text: '<span style="font-size: 14px;">'+result.info+'</span>',
                });
                return false;
            } else if (result.code == 0) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons" style="font-size: 24px;">check</span>',
                    color: "success",
                    title: '<span style="font-size: 18px;">Success</span>',
                    text: '<span style="font-size: 14px;">Register success</span>',
                });
                window.open('/index/user/agreement','_self');
            }
        },
        goback(){
            window.history.go(-1);
        },
        // 去登录
        goLogin() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>
	body {
		font-size: 1rem;
		color: #333;
	}
	.cont {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background: url(../assets/background.png) no-repeat;
		background-size: 100% 100%;
	}
	
	.imgList {
        position: relative;
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: flex-start;
	}

	.imgList a {
		margin-left: 16px;
	}

	.imgList img {
		width: 30px;
		height: 30px;
	}
	
	.logo {
        display: flex;
        flex-wrap: nowrap;
        height: 130px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
	}
	
	.logo div {
		font-size: 22px;
        font-weight: 500;
	}

	.content {
		padding: 0 28px;
	}

	.box {
		margin-top: 2vh;
	}
	ul, li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.box ul li {
		background-color: hsla(0, 0%, 98%, .5);
		border-color: #ffffff;
		height: 7vh;
		border-radius: 25px;
		display: flex;
		align-items: center;
		padding: 11px;
	}
	.box1 ul li + li, .box2 ul li + li {
		margin-top: 3vh;
	}
	.box ul li .ico {
		width: 22px;
	}
	img {
		vertical-align: middle;
	}
	.box ul li input {
		font-size: 16px;
		background: transparent;
		flex: 1;
		color: #fff;
		text-align: center;
	}
	button, input, select {
		outline-style: none;
		border: 0;
	}

	
	.loginBtn {
		height: 100px;
		border: 0px solid black;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	.btn-inner {
		height: 50px;
		line-height: 50px;
		background-color: rgb(225, 225, 225);
		border-radius: 25px;
		transition-duration: 0s;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
	}
	.tips {
		display: flex;
		width: 100%;
		text-align: center;
		font-size: 14px;
		color: #fff;
		flex-wrap: nowrap;
		justify-content: center;
		height: 40px;
		align-items: center;
	}
</style>
